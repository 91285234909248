import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

function enableBodyScrollLock(element) {
	// prevents body scrolling
	disableBodyScroll(element);
} // enableBodyScrollLock()


function disableBodyScrollLock(element) {
	// restores body scrolling
	enableBodyScroll(element);
} // disableBodyScrollLock()

export {
	enableBodyScrollLock,
	disableBodyScrollLock
}