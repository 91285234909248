import {
	enableBodyScrollLock,
	disableBodyScrollLock
} from './vendor/custom/body_scroll_lock/body_scroll_lock';

/**************************************************
*	global variables
*/

// for controlling window.onhashchange functionality with popup -> send file buttons
window.submitFileButtonClicked = false;


/**************************************************
*	general methods
*/

function setLinks(anchors) {
    anchors.each(function () {
        if (this.getAttribute("rel") == "external") {
            this.target = "_blank";
        }
		
        if (this.getAttribute("rel") == "no-click") {
            $(this).click(function (event) {
            	event.preventDefault();
            });
        }
    });
  
    return;
} // setLinks()


function setScrollToTopEvent() {
	// sets up a button with all events to scroll back to top of page
	// called from any page on dom ready, e.g. equipment -> index, employees -> index...
	
	// check to see if scroll to top button exists in html
	var scrollToTopButton = $("#scroll-top-button");
	
	// if button does not exist in html, create it
	// this also resets the scrollToTopButton variable
	if (!scrollToTopButton.length) {
		var scrollToTopButton = $('<button id="scroll-top-button">Top</button>');
		
		// append buttom before closing </body>
		$('body').append(scrollToTopButton);
	}
	
	// show/hide scroll to top button
	$(window).scroll(function () {
    	if ($(this).scrollTop() > 30) { // this points to window
			scrollToTopButton.addClass("active");
    	}
		else {
			scrollToTopButton.removeClass("active");
		}
	});
	
	// set click event to scroll back to top of page
	scrollToTopButton.click(function () {
    	$("html, body").animate({ 
    		scrollTop: 0 
    	}, "fast");
	
    	return false;
 	});
} // setScrollToTopEvent()


function setStickyElements(element, classname) {
	// sets an element to be "sticky" when scrolling
	// 	- different classnames can be passed in to determine where the element is to stick, e.g. top, bottom...
	//		- currently ONLY supporting "sticky-scroll-top"
	//		- if suport for "sticky-scroll-bottom" is ever implemented, will need to do insertAfter() when inserting cloned element
	// 	- to avoid page jumping, a cloned element is inserted directly before the element when it goes sticky and removed when it's no longer sticky
	//		- to see the page jump when scrolling, just comment out clonedElement.insertBefore() and clonedElement.remove()
	var elementOffset = element.offset();
	var clonedElement = element.clone();
	
	$(window).scroll(function () {
    	if (window.pageYOffset > elementOffset.top) {
			element.addClass(classname);
			clonedElement.addClass("invisible");
			clonedElement.insertBefore(element);
    	}
		else {
			element.removeClass(classname);
			clonedElement.remove();
		}
	});
} // setStickyElements()


function collapseAllElements(elements) {
	// collapses any set of elements that uses the "minimized" class
	elements.addClass("minimized");
} // collapseAllElements()


function expandAllElements(elements) {
	// expands any set of elements that uses the "minimized" class
	elements.removeClass("minimized");
} // expandAllElements()


function showAlerts() {
	var alerts = $(".alert");

	alerts.each(function () {
		if ($(this).hasClass("fade")) {
			$(this).delay(5000).fadeOut(1000);
		}
	});
} // showAlerts()


function notify(classnames, message) {
	// display's top centered notice to user
	var body = $('body');
	var html = $('<div class="notify ' + classnames + '">' + message + '</div>');
	
	body.prepend(html);
	
	var notifyContainer = body.find("div.notify");
	var width = notifyContainer.outerWidth();
	var offset = width / 2;
	
	notifyContainer.css("margin-left", "-" + offset + "px");
	
	setTimeout(function () {
		notifyContainer.fadeOut(1000, function () {
	    	// animation complete.
	  	});
	}, 2500);
} // notify()


function showAlertBar(html, callback) {
	var body = $("body");
	var alertBarHtml = $('<div class="alert danger fixed page-centered">' + html + '</div>');
	
	// prepend to body
	body.prepend(alertBarHtml);
	
	// get dimensions of html to center horz and vert in page
	var width = alertBarHtml.outerWidth();
	var height = alertBarHtml.outerHeight();
	
	// center html on page
	alertBarHtml.css({
		"top": "50%",
		"left": "50%",
		"margin-top": "-" + (height / 2) + "px",
		"margin-left": "-" + (width / 2) + "px"
	});
	
	// call callback function to set events on buttons
	callback(alertBarHtml);
} // showAlertBar()


function removeAlertBar() {
	$(".alert.page-centered").remove();
} // removeAlertBar()


/**************************************************
*	cookies
* 	- https://www.the-art-of-web.com/javascript/setcookie/
* 	- https://www.the-art-of-web.com/javascript/getcookie/
*/

function setCookie(cookieName, cookieValue) {
	var today = new Date();
	var expiry = new Date(today.getTime() + 365 * 24 * 3600 * 1000); // plus 1 year
	
	document.cookie = cookieName + "=" + escape(cookieValue) + "; path=/; expires=" + expiry.toGMTString();
} // setCookie()


function getCookie(cookieName) {
	var regex = new RegExp(cookieName + "=([^;]+)");
	var value = regex.exec(document.cookie);
	
	return (value != null) ? unescape(value[1]) : null;
} // getCookie()


function deleteCookie(cookieName) {
	var today = new Date();
	var expired = new Date(today.getTime() - 24 * 3600 * 1000); // less 24 hours
	
	document.cookie = cookieName + "=null; path=/; expires=" + expired.toGMTString();
} // deleteCookie()


/**************************************************
*	input controls
*/

function initializeTimePickerControls(timePickerContainer) {
	var timePicker = timePickerContainer.find(".time-picker");
	var clearControl = timePickerContainer.find(".input-button.clear");
	
	clearControl.click(function (event) {
		// clear time picker
		timePicker[0]._flatpickr.clear();
	});
} // initializeTimePickerControls()


/**************************************************
*	date and time pickers
*/

function initializeTimePicker(elements) {
	elements.flatpickr({
		enableTime: true,
		noCalendar: true,
		dateFormat: "H:i",
		altInput: true,
		altFormat: "h:i K",
		onReady: function (selectedDates, dateStr, instance) {
			// on mobile, flatpickr automatically replaces input/text field with input/date or input/time (checking for both in case we need to handle date fields in the future)
			// when this happens, remove our custom time icon from parent time picker container
			// if parent container hasClass "time-picker-container" and time element type attribute is "date", add "no-icon" class to parent container
			var timepicker = $(this.element);
			var pickerContainer = timepicker.parents(".time-picker-container");
			var replacedOnMobileElements = pickerContainer.find('input[type="date"], input[type="time"]');
	
			if (replacedOnMobileElements.length) {
				pickerContainer.addClass("no-icon");
			}
		}
	});
} // initializeTimePicker()


/**************************************************
*	wysiwyg editors
*/

function initializeTinyMce(element) {
	tinymce.init({
		target: element,
		toolbar: 'undo redo | formatselect | bold italic | forecolor backcolor | link image | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent',
		plugins: "link textcolor lists table",
		menubar: true,
		height: 350,
		branding: false
  	});
} // initializeTinyMce()


/**************************************************
*	navigation methods
*/

function togglePrimaryNavigation() {
	var listItems = $("nav.primary ul li");
	
	listItems.each(function () {
		var t = $(this);
		
		if (t.hasClass("has-sub")) {
			t.find(".trigger").on('click', function (event) {
				event.preventDefault();
				event.stopPropagation();
				
				if (jQuery.browser.mobile) {
					t.addClass("no-touch");
				}
				
				t.toggleClass("open");
			});
		}
	});
} // togglePrimaryNavigation()


function toggleDropNavigations(triggers) {
	triggers.on('click', function (event) {
		var dropdownMenu = $(this).find(".dropdown-menu");
		
		// show menu
		dropdownMenu.toggleClass("active");
		
		// reposition menu if any part of it is off screen
		// idea from: https://stackoverflow.com/a/11525189
          var menuOffset = dropdownMenu.offset();
          var menuLeft = menuOffset.left;
          var menuWidth = dropdownMenu.width();
          var bodyHeight = $("body").height();
          var bodyWidth = $("body").width();
          var isEntirelyVisible = (menuLeft + menuWidth <= bodyWidth);
		
		//console.log(menuOffset);
		//console.log("menuLeft: " + menuLeft);
		//console.log("menuWidth: " + menuWidth);
		//console.log("bodyHeight: " + bodyHeight);
		//console.log("bodyWidth: " + bodyWidth);
		//console.log("(menuLeft + menuWidth <= bodyWidth): " + (menuLeft + menuWidth <= bodyWidth));

          if (!isEntirelyVisible) {
              dropdownMenu.addClass('edge');
          } else {
              dropdownMenu.removeClass('edge');
          }
	});
} // toggleDropNavigations()


function dismissDropNavigation(event) {
	var closestDropdownControl = $(event.target).closest('.has-dropdown');
	var thisDropdownMenu = closestDropdownControl.find(".dropdown-menu");
	var allDropDownMenusExceptThisOne = $(".dropdown-menu").not(thisDropdownMenu);
	//console.log(event);
	//console.log(closestDropdownControl);
	//console.log(closestDropdownControl[0]);
	//console.log(allDropDownMenusExceptThisOne);
	
	allDropDownMenusExceptThisOne.each(function () {
		var dropdownMenu = $(this);
		var dropDownTrigger = dropdownMenu.parent(".has-dropdown");
		
		dropdownMenu.removeClass("active edge");
		dropDownTrigger.removeClass("active"); // if menu item is sticky (e.g. allocations toolbar)
	});
} // dismissDropNavigation()


// hide any open menus on page when clicking outside of an open menu
$(document).on('click touchstart', function (event) {
	dismissDropNavigation(event);
});


function setControlsToggleEvent(parentContainer, toggleObject, controlClassname) {
	parentContainer.find(controlClassname).click(function (event) {
		var control = $(this);
		
		performControlToggle(control, toggleObject);
	});
} // setControlsToggleEvent()


function performControlToggle(control, toggleObject) {
	if (toggleObject.hasClass("closed")) {
		performControlToggleMaximize(control, toggleObject);
	
		return;
	}

	performControlToggleMinimize(control, toggleObject);
} // performControlToggle()


function performControlToggleMinimize(control, toggleObject) {
	control.addClass("maximize");
	toggleObject.addClass("closed");
	control.attr("title", "Minimize");
} // performControlToggleMinimize()


function performControlToggleMaximize(control, toggleObject) {
	control.removeClass("maximize");
	toggleObject.removeClass("closed");
	control.attr("title", "Maximize");
} // performControlToggleMaximize()


/**************************************************
*	notification methods
*/

function prependNewNotifications(notifications_to_append) {
	// notifications_to_append is passed in as an array of html strings
	var notificationsContainer = $("ul.notifications");
	
	for (var i = 0; i < notifications_to_append.length; i++) {
		var notification_to_append = $(notifications_to_append[i]);
		
		setNotificationEvents(notification_to_append);
		
		notificationsContainer.prepend(notification_to_append);
		
		// start time ago plugin for notification
		notification_to_append.find("time.timeago").timeago();
	}
} // prependNewNotifications()


function updateNotificationsBadge(unread_count) {
	var headerNotificationIcon = $(".control.notifications .fa-bell");
	var badge = $(".control.notifications .badge");
	
	if (badge.length == 0 && unread_count > 0) { // is badge currently showing?
		headerNotificationIcon.append('<span class="badge important header control">' + unread_count + '</span>');
	}
	else {
		if (unread_count == 0) {
			badge.remove();
		}
		else {
			badge.html(unread_count);
		}
	}
} // updateNotificationsBadge()


function setNotificationEvents(notification) {
	var notificationTrashIcon = notification.find(".trash");
	var notificationToggleReadStatusIcon = notification.find(".toggle.read-status");
	var notificationLinks = notification.find("a"); // used to only mark notifications as read
	
	setNotificationTrashEvent(notificationTrashIcon);
	
	notificationToggleMarkAsRead(notificationToggleReadStatusIcon);
	
	notificationMarkAsRead(notificationLinks);
} // setNotificationEvents


function notificationMarkAsRead(notificationLinks) {
	notificationLinks.each(function () {
		var e = $(this);
		var notification = e.parents("li.notification");
		var readStatusIcon = notification.find(".read-status.icon");
		
		e.click(function (event) {
			event.preventDefault();
			
			var recordID = $(this).parents('li.notification').attr("data-record-id");
			var url = '/notifications/mark-as-read/' + recordID;
			var href = $(this).attr("href");
			var actionMessage = "Marking Notification as Read";
			
			doAjax({
				actionMessage: actionMessage,
	    		type: "PUT",
	    		url: url
	    	}).done(function (data, textStatus, jqXHR) {
				// mark notification as read
				// to help with browser caching in the following scenario...
				// 	- a User clicks on a notification link, then clicks the back button on their browswer. to help the notification show as marked as "read" in browswer memory/cache
				//notification.addClass("read");
				//readStatusIcon.removeClass("fas").addClass("far");
				
				window.location.href = href;
			}); // doAjax()
		});
	});
} // notificationMarkAsRead()


function notificationToggleMarkAsRead(e) {
	e.click(function (event) {
		var recordID = e.parents('li.notification').attr("data-record-id");
		var url = '/notifications/toggle-mark-as-read/' + recordID;
		var actionMessage = "Toggling Notification Read Status";
	
		doAjax({
			actionMessage: actionMessage,
    		type: "PUT",
    		url: url
    	}).done(function (data, textStatus, jqXHR) {
			var iconClassname = (data.read_status == true) ? "far fa-circle" : "fas fa-circle";
			var readStatusClassname = (data.read_status == true) ? "read" : "";
			var title = (data.read_status == true) ? "Mark as Unread" : "Mark as Read";
	
			e.removeClass("far fa-circle fas fa-circle");
			e.removeClass("read");
			e.addClass(iconClassname + ' ' + readStatusClassname);
			e.attr("title", title);
			e.parents("li.notification").toggleClass("read");
			
			// get updated unread notifications count
			var unreadNotificationsCount = $("ul.notifications li.notification").not(".read").length;
	
			updateNotificationsBadge(unreadNotificationsCount);
		}); // doAjax()
	});
} // notificationToggleMarkAsRead()


function setNotificationTrashEvent(e) {
	e.click(function (event) {
		if (confirm("Delete this notification?")) {
			var notification = $(this).parents('li.notification');
			var recordID = notification.attr("data-record-id");
		
			deleteNotification(recordID, notification);
		}
		else {
			event.preventDefault();
		}
	});
} // setNotificationTrashEvent()


function deleteNotification(recordID, notification) {
	var url = '/notifications/' + recordID;
	var actionMessage = "Deleting Notification";
	
	doAjax({
		actionMessage: actionMessage,
  		type: "DELETE",
  		url: url
  	}).done(function (data, textStatus, jqXHR) {
		// highlight and remove from DOM
		notification.addClass("highlight deleted");

		notification.fadeOut(1000, function () {
		    notification.remove();

			updateNotificationsBadge(data.unread_count);

			var updatedTrashIcons = $("li.notification .trash"); // get new number of trash icons to determine if any notifcations are left

			if (updatedTrashIcons.length == 0) {
				window.location.reload();
			}
		});
	}); // doAjax()
} // deleteNotification()


/**************************************************
*	form methods
*/

function dynamicFormFields(config) {
	var containers = config.container;
	
	containers.each(function () {
		var container = $(this);
		var fieldsContainer = container.find("ul.fields");
		var addButton = container.find(config.addButtonSelector);
		var parentSelector = config.parentSelector;
		var deleteButtonSelector = config.deleteButtonSelector;
		var existingFields = fieldsContainer.find(parentSelector + " " + deleteButtonSelector); // for edit views if there are existing fields from DB
		var axis = config.axis || "y";
	
		// set sortable if this is a sortable list
		if (fieldsContainer.hasClass("sortable")) {
			fieldsContainer.sortable({
				handle: ".handle",
				axis: axis
			});
		}
	
		addButton.click(function () {
			var addButton = $(this);
			var model = addButton.prev("ul.fields").data("model");
			var fieldName = addButton.prev("ul.fields").data("field-name");
			var fieldTypeToUse = addButton.prev("ul.fields").data("field-type-to-use");
			var handleClass = (fieldTypeToUse == "textarea") ? "" : " single";
			var formSubmissionView = false; // default
			
			// NOTE: on Form Submissions, the name attribute is much different, e.g. group, set and field IDs
			// on Form Submission view, set group, set and field name attribute segment
			var fieldsGroup = addButton.parents(".fields-group");
			var fieldsSet = addButton.parents(".fields-set");
			var field = addButton.parents(".field");
			
			if (fieldsGroup.length && fieldsSet.length && field.length) {
				formSubmissionView = true;
				var groupId = fieldsGroup.data("group-id");
				var setId = fieldsSet.data("set-id");
				var formFieldId = field.attr("data-form-field-id");
				var nameAttributeSegment = "NULL"; // for help in caching errors
			
				if (typeof groupId != "undefined" && typeof setId != "undefined" && typeof formFieldId != "undefined") {
					nameAttributeSegment = "[groups][" + groupId + "][sets][" + setId + "][fields][" + formFieldId + "][data]";
				}
			}
			
			var newItemHTML = '<li class="row">';
				newItemHTML += '<div class="sortable-container">';
					newItemHTML += '<div class="sortable-element">';
						newItemHTML += '<span class="handle' + handleClass + '">';
							newItemHTML += '<i class="fa fa-bars top" aria-hidden="true"></i> ';
							if (fieldTypeToUse == "textarea") {
								newItemHTML += '<i class="fa fa-bars bottom" aria-hidden="true"></i>';
							}
		            	newItemHTML += '</span>';
						
						if (formSubmissionView) {
							// form submission
							if (fieldTypeToUse == "textarea") {
								newItemHTML += '<textarea name="' + model + nameAttributeSegment + '[]"></textarea>';
							}
							else {
								newItemHTML += '<input type="text" name="' + model + nameAttributeSegment + '[]">';
							}
						}
						else {
							// default
							if (fieldTypeToUse == "textarea") {
								newItemHTML += '<textarea name="' + model + '[' + fieldName + '][]"></textarea>';
							}
							else {
								newItemHTML += '<input type="text" name="' + model + '[' + fieldName + '][]">';
							}
						}
						
					newItemHTML +=  '</div><!-- .sortable-element -->';
			
					newItemHTML += ' <span class="controls">';
					newItemHTML += '<i class="fa fa-minus-circle delete" title="Remove"></i>';
					newItemHTML += '</span><!-- .controls -->';
	          	newItemHTML += '</div><!-- .sortable-container -->'
			newItemHTML += '</li>';
			
			var newElement = $(newItemHTML);
			
			// set controls to display block if useing textarea field type
			if (fieldTypeToUse == "textarea") {
				newElement.find(".controls").addClass("block");
			}
			
			var deleteButton = newElement.find(deleteButtonSelector);
		
			deleteButton.click(function (event) {
				if (confirm("Delete this field?")) {
					$(this).closest(parentSelector).remove();
				}
			
				event.preventDefault();
			});
		
			fieldsContainer.append(newElement);
		});
	
		existingFields.click(function (event) {
			if (confirm("Delete this field?")) {
				$(this).closest(parentSelector).remove();
			}
		
			event.preventDefault();
		});
	});
} // dynamicFormFields()


function generateHexString(len) {
	// idea: https://stackoverflow.com/a/8084248
	// NOTE: substring(2, length) does the following...
	//	- the "2" removes the first two characters, e.g. "0."
	//	- the len variable controls the length of the string returned
	return Math.random().toString(36).substr(2, len);
} // generateHexString()


function generateUUID() {
	// https://stackoverflow.com/a/105074
  	function s4() {
    	return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  	}
  	
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
} // generateUUID()


function idExists(id, selector, dataAttributes) {
	// checks to see if the newly generated ID is found anywhere on page
	//	- check all elements by selector that have a data attribute that stores an ID
	//	- args
	//		- id: newly generated ID
	//		- selector: CSS class to select all targeted elements
	//		- dataAttributes: array of data-xxxx-xxxx attributes to check for existing IDs on page
	// 	- returns boolean
	var elements = $(selector);
	var idFound = false;
	
	// iterate through all elements on page that have class .uuid
	elements.each(function () {
		var element = $(this);
		
		// iterate through dataAttributes to determine if each element has one of the data attributes listed in dataAttributes
		for (var i = 0; i < dataAttributes.length; i++) {
			var dataAttribute = element.attr(dataAttributes[i]);
			
			// if dataAttribute is not "undefined", return ID
			if (typeof dataAttribute != "undefined") {
				if (id == dataAttribute) {
					idFound = true;
					
					return;
				}
			}
		}
	});
	
	return idFound;
} // idExists()


/**************************************************
*	report methods
*/

function setDraggableOnFormField(element) {
	// sets the draggable event on a Form field in the left panel
	element.draggable({
		helper: "clone",
		appendTo: ".left-panel.builder",
		connectToSortable: "ul.build-items"
	});
} // setDraggableOnFormField()


/**************************************************
*	UI methods
*/

// asset allocations dragging
var scrollUpInProgress = false;
var scrollDownInProgress = false;
var scrollLeftInProgress = false;
var scrollRightInProgress = false;

function dragging(event, ui, element) {
//////////////////////////////////////////////////
// 	handles horizontal and vertical scrolling when 
//	elements area dragged. when a draggable/cursor 
//	goes over the top or bottom trigger area of 
//	the window, the appropriate up or down 
//	scrolling will happen
//	Notes: this method is used in allocations for left/right/up/down scrolling on workers, leaders and groups
	var elementToScrollUpDown = $("html, body");
	var elementToScrollLeftRight = $(".allocations .teams");

	var assetInRepo = $(element).is('.asset.repo');
	var group = $(element).is('.groups');
	var leader = $(element).is('.asset.leader');
	var worker = $(element).is('.asset.worker');
	
	var elementIsDragging = $(ui.helper).is('.ui-draggable-dragging'); // workers and leaders are "elements"
	var groupIsDragging = $(ui.helper).is('li.draggable.group'); // specifically detect if group is dragging
	
	//console.log("document width: " + $(document).width());
	//console.log("window innerWidth: " + $(window).innerWidth());
	//console.log("cursor X - scrollLeft: " + (event.pageX - $(window).scrollLeft()));
	//console.log("scrollLeft: " + $(window).scrollLeft());
	
	//console.log("document height: " + $(document).height());
	//console.log("window innerHeight: " + $(window).innerHeight());
	//console.log("cursor Y - scrollTop: " + (event.pageY - $(window).scrollTop()));
	//console.log("scrollTop: " + $(window).scrollTop());
	
	//console.log(ui);
	//console.log("elementIsDragging: " + elementIsDragging);
	//console.log("groupIsDragging: " + groupIsDragging);
	//return; // remove after testing
	
	// only allow scroll to be triggered when groups, leaders or employees are dragged in allocations
	if (assetInRepo || group || leader || worker) {
		if (elementIsDragging || groupIsDragging) {
			// vertical variables
			var windowHeight = $(document).height();
			var windowInnerHeight = $(window).innerHeight();
		  	var scrollToTop = $(window).scrollTop();
			var upDownTriggerArea = 40;
			
			// horizontal variables
			var elementWidth = $(document).width();
			var elementInnerWidth = $(window).innerWidth();
			var scrollToLeft = $(window).scrollLeft();
			var leftRightTriggerArea = 40;
			
			//if (assetInRepo) { console.log("assetInRepo: " + assetInRepo); }
			//if (group) { console.log("group: " + group); }
			//if (leader) { console.log("leader: " + leader); }
			//if (worker) { console.log("worker: " + worker); }
			
			//////////////////////////////////////////////////
			//	left/right trigger scrolling for all groups, 
			//	leaders and workers
			
			//console.log("(event.pageX - scrollToLeft): " + (event.pageX - scrollToLeft));
			//console.log("leftRightTriggerArea: " + leftRightTriggerArea);
			
		    // left
			if ((event.pageX - scrollToLeft) <= leftRightTriggerArea) {	
				if (!scrollLeftInProgress) {
				  	//console.log("LEFT");
				 	scrollLeftInProgress = true;
		
					elementToScrollLeftRight.animate({
						scrollLeft: 0
					},{
					  	duration: 1000,
					  	complete: function () {
						  	scrollLeftInProgress = false;
						  	//console.log("Animation left complete");
					  	}
				 	});
				}
		    }
			else {
				if (scrollLeftInProgress) {
					elementToScrollLeftRight.stop();
					scrollLeftInProgress = false;
				}
			}
		
			// right
			if ((event.pageX - scrollToLeft) >= (elementInnerWidth - leftRightTriggerArea)) {
				if (!scrollRightInProgress) {
					//console.log("RIGHT");
					scrollRightInProgress = true;

					elementToScrollLeftRight.animate({
						scrollLeft: elementToScrollLeftRight[0].scrollWidth
					},{
						duration: 1000,
						complete: function () {
							scrollRightInProgress = false;
						  	//console.log("Animation right complete");
						}
					});
				}
			}
			else {
				if (scrollRightInProgress) {
					elementToScrollLeftRight.stop();
					scrollRightInProgress = false;
				}
			}

			
			//////////////////////////////////////////////////
			//	up/down trigger scrolling for all groups, 
			//	leaders and workers
			
		    // up
			if ((event.pageY - scrollToTop) <= upDownTriggerArea) {	
				if (!scrollUpInProgress && scrollToTop != 0) {
					//console.log("up");
					scrollUpInProgress = true;
		
				  elementToScrollUpDown.animate({
					  scrollTop: 0
				  },{
					  duration: 1000,
					  complete: function () {
						  scrollUpInProgress = false;
						  //console.log("Animation down complete");
					  }
				  });
				}
		    }
			else {
				if (scrollUpInProgress) {
					elementToScrollUpDown.stop();
					scrollUpInProgress = false;
				}
			}
		
			// down
			if ((event.pageY - scrollToTop) >= (windowInnerHeight - upDownTriggerArea)) {
				if (!scrollDownInProgress) {
					//console.log("down in");
					scrollDownInProgress = true;

					elementToScrollUpDown.animate({
						scrollTop: $(document).height()
					},{
						duration: 1000,
						complete: function () {
							scrollDownInProgress = false;
						  	//console.log("Animation down complete");
						}
					});
		    	}
			}
			else {
				if (scrollDownInProgress) {
					elementToScrollUpDown.stop();
					scrollDownInProgress = false;
				}
			}
		} // if ($(ui.helper).is('.ui-draggable-dragging') || $(ui.helper).is('li.draggable.group'))
	} // if (group || leader || worker)
} // dragging()


/**************************************************
*	functions: forms
*/

function getElementValue(element) {
	// gets the value for an input, textarea or contenteditable element
	var value = null;
	
	if (element.is("input") || element.is("textarea")) {
		value = element.val();
	}
	else {
		value = element.text();
	}
	
	return value;
} // getElementValue()


function copySelectValues(originalSelects, clonedSelects) {;
	originalSelects.each(function (index) {
		var originalSelect = $(this);
		
		// copy value from original select to cloned select
		$(clonedSelects[index]).val(originalSelect.val());
	});
} // copySelectValues()


function getInputValue(element) {
	// gets the value of a form input (e.g. inout, select, textarea...)
	if (element.is(":checkbox") || element.is(":radio")) {
		return element.is(':checked');
	}
	
	return element.val();
} // getInputValue()


/**************************************************
* general file uploads
*/

function uploadedFilesExist(fileUploader) {
	// validates if there are uploaded files
	// returns boolean
	var uploadedFiles = fileUploader.find("ul.file-list li.file");
	
	if (uploadedFiles.length) {
		return true;
	}
	
	return false;
} // uploadedFilesExist()


function processingFilesExist(fileUploader) {
	// validates if there are uploaded files still processing
	// returns boolean
	var processingFiles = fileUploader.find("ul.file-list li.file.processing");
	
	if (processingFiles.length) {
		return true;
	}
	
	return false;
} // processingFilesExist()


/**************************************************
*	general html
*/

function updateProgressBarPercentage(progressBar, percentComplete) {
	var bar = progressBar.find(".bar");
	var placeholder = progressBar.find(".placeholder");
	
	// update percentage comlete
	bar.html(percentComplete);
	bar.css("width", percentComplete);
	
	// show placeholder of percent complete is 0%
	if (percentComplete == "0%") {
		bar.removeClass("text-white");
	}
	else {
		bar.addClass("text-white");
	}
} // updateProgressBarPercentage()


function dynamicRecordsPlaceholderHtml(id, placeholderClassnames, statusText, innerOverlayClassnames) {
	return '<div id="' + id + '" class="placeholder ' + placeholderClassnames + '">' +
		innerOverlayStatusHtml(statusText, innerOverlayClassnames) + 
	'</div>';
} // dynamicRecordsPlaceholderHtml()


function buildListFillHtml() {
	var html = '<li class="j fill"></li> ' +
	'<li class="j fill"></li> ' + 
	'<li class="j fill"></li> ' +
	'<li class="last"></li>';
	
	return html;
} // buildListFillHtml()


function buildFullAddress(item) {
	var street_address = item.street_address || item.comp_street_address;
	var extended_address = item.extended_address || item.comp_extended_address;
	var locality = item.locality || item.comp_locality;
	var region = item.region || item.comp_region;
	var postal_code = item.postal_code || item.comp_postal_code;
	
	return [
		street_address, 
		extended_address, 
		locality, 
		region, 
		postal_code
	].join(" ");
} // buildFullAddress()


/**************************************************
*	select2 html
*/

// SIMPLE
// users
function formatSimpleUserItem(item) {
	// this function...
	//	- formats how the select2 -> options look
  if (item.loading) {
    return item.text;
  }

  var html = '<div class="select2-result clearfix">' +
    '<div class="select2-result meta">' +
			'<span class="select2-result primary user-full-name">' + item.last_name + ', ' + item.first_name + '</span> ';

	if (item.title) {
		html += '<div class="select2-result sub employee-title">Title: ' + item.title + '</div>';
	}

  html += '</div></div>';

  return html;
} // formatSimpleUserItem()


function formatSimpleUserItemSelection(item) {
	// this function...
	// 	- formats how the select2 selected option looks and functions
	// 	- adds data attributes to the select2 "option" elements

	// set data attributes on select2's "option" elements
	// https://stackoverflow.com/a/48002164
	$(item.element).attr('data-user-id', item.id);
	$(item.element).attr('data-first-name', item.first_name);
	$(item.element).attr('data-last-name', item.last_name);

	var nameOrText = (item.last_name + ", " + item.first_name) || item.text;

	var html = '<div class="select2-selection primary user-full-name">' + nameOrText + '</div>';

	html += '<div class="select2-selection sub employee-title">Title: ' + item.title + '</div>';

  return html;
} // formatSimpleUserItemSelection()
// --users

// employees
function formatSimpleEmployeeItem(item) {
	// this function...
	//	- formats how the select2 -> options look
  if (item.loading) {
    return item.text;
  }

  var html = '<div class="select2-result clearfix">' +
    '<div class="select2-result meta">' +
			'<span class="select2-result primary employee-full-name">' + item.last_name + ', ' + item.first_name + '</span> ';

	if (item.title) {
		html += '<div class="select2-result sub employee-title">' + item.title + '</div>';
	}

  html += '</div></div>';

  return html;
} // formatSimpleEmployeeItem()


function formatSimpleEmployeeItemSelection(item) {
	// this function...
	// 	- formats how the select2 selected option looks and functions
	// 	- adds data attributes to the select2 "option" elements

	// set data attributes on select2's "option" elements
	// https://stackoverflow.com/a/48002164
	$(item.element).attr('data-record-id', item.id);
	$(item.element).attr('data-first-name', item.first_name);
	$(item.element).attr('data-last-name', item.last_name);

	var nameOrText = (item.last_name + ", " + item.first_name) || item.text;

	var html = '<div class="select2-selection primary employee-full-name">' + nameOrText + '</div>';

	html += '<div class="select2-selection sub employee-title">' + item.title + '</div>';

  return html;
} // formatSimpleEmployeeItemSelection()
// --employees


// equipment
function formatSimpleEquipmentItem(item) {
	// this function...
	//	- formats how the select2 -> options look
  if (item.loading) {
    return item.text;
  }
  
  var identifierText = buildEquipmentIdentifierText(item);

  var html = '<div class="select2-result clearfix">' +
    '<div class="select2-result meta">' +
			'<span class="select2-result primary equipment-identifier-text">' + identifierText + '</span> ';

	if (item.title) {
		html += '<div class="select2-result sub equipment-category">' + item.category + '</div>';
	}

  html += '</div></div>';

  return html;
} // formatSimpleEquipmentItem()


function formatSimpleEquipmentItemSelection(item) {
	// this function...
	// 	- formats how the select2 selected option looks and functions
	// 	- adds data attributes to the select2 "option" elements

	// set data attributes on select2's "option" elements
	// https://stackoverflow.com/a/48002164
	$(item.element).attr('data-record-id', item.id);
	$(item.element).attr('data-category', item.category);
	$(item.element).attr('data-sub-category', item.sub_category);
	$(item.element).attr('data-year', item.year);
	$(item.element).attr('data-make', item.make);
	$(item.element).attr('data-model', item.model);
	$(item.element).attr('data-serial-number', item.serial_number);
	$(item.element).attr('data-equipment-number', item.equipment_number);
	$(item.element).attr('data-descriptor', item.descriptor);

	var identifierText = buildEquipmentIdentifierText(item);
	var idText = item.text; // default
	
	if ($.trim(identifierText != "")) {
		idText = identifierText;
	}

	var html = '<div class="select2-selection primary equipment-identifier-text">' + idText + '</div>';

	html += '<div class="select2-selection sub equipment-category">' + item.category + '</div>';

  return html;
} // formatSimpleEquipmentItemSelection()


function buildEquipmentIdentifierText(item) {
	  var identifierText = "";
  
	  if ($.trim(item.year) != "") { identifierText += item.year + " | "; }
	  if ($.trim(item.make) != "") { identifierText += item.make + " | "; }
	  if ($.trim(item.model) != "") { identifierText += item.model + " | "; }
	  if ($.trim(item.serial_number) != "") { identifierText += item.serial_number + " | "; }
	  if ($.trim(item.equipment_number) != "") { identifierText += item.equipment_number + " | "; }
	  if ($.trim(item.descriptor) != "") { identifierText += item.descriptor + " | "; }
  
  return identifierText;
} // buildEquipmentIdentifierText()
// --equipment
// --SIMPLE


// FULL
function formatFullCompanyItem(item) {
	// this function...
	//	- formats how the select2 -> options look
	// 	- shows Company data (FULL)
  if (item.loading) {
	  return item.text;
  }

  var html = '<div class="select2-result clearfix">' +
  	  '<div class="select2-result meta">' +
	      '<span class="select2-result primary company-name">' + item.name + '</span> ';

	  if (item.type_of) {
		  html += '<div class="select2-result sub type-of">' + item.type_of + '</div>';
	  }
	  
	  if (item.full_address) {
	  	html += '<div class="select2-result sub full-address">' + item.full_address + '</div>';
	  }
	  
  html += '</div></div>';

  return html;
} // formatFullCompanyItem()


function formatFullCompanyItemSelection(item) {
	// this function...
	// 	- formats how the select2 selected option looks and functions
	// 	- adds data attributes to the select2 "option" elements
	// 	- shows Company data (FULL)
	
	// set data attributes on select2's "option" elements
	// https://stackoverflow.com/a/48002164
	$(item.element).attr('data-company-id', item.id);
	$(item.element).attr('data-name', item.name);
	
	var nameOrText = item.name || item.text;
	
	var html = '<div class="select2-selection primary company-name">' + nameOrText + '</div>';
	
  return html;
} // formatFullCompanyItemSelection()


function formatFullProjectItem(item) {
	// this function...
	//	- formats how the select2 -> options look
	// 	- shows Project data (FULL)
  if (item.loading) {
	  return item.text;
  }

  var html = '<div class="select2-result clearfix">' +
  	  '<div class="select2-result meta">' +
	      '<span class="select2-result primary job-name">' + item.name + '</span> ';
	  
	  if (item.job_number) {
		  html += '<div class="select2-result sub job-number">job #: ' + item.job_number + '</div>';
	  }
	  
	  if (item.full_address) {
	  	html += '<div class="select2-result sub full-address">' + item.full_address + '</div>';
	  }
	  
  html += '</div></div>';

  return html;
} // formatFullProjectItem()


function formatFullProjectItemSelection(item) {
	// this function...
	// 	- formats how the select2 selected option looks and functions
	// 	- adds data attributes to the select2 "option" elements
	// 	- shows Project data (FULL)
	
	// set data attributes on select2's "option" elements
	// https://stackoverflow.com/a/48002164
	$(item.element).attr('data-job-id', item.id);
	$(item.element).attr('data-name', item.name);
	
	var nameOrText = item.name || item.text;
	
	var html = '<div class="select2-selection primary job-name">' + nameOrText + '</div>';
	
  return html;
} // formatFullProjectItemSelection()


function formatFullUserItem(item) {
	// this function...
	//	- formats how the select2 -> options look
	// 	- shows User with Employee and Company data (FULL)
  if (item.loading) {
    return item.text;
  }

  var html = '<div class="select2-result clearfix">' +
    '<div class="select2-result meta">' +
			'<span class="select2-result primary user-full-name">' + item.last_name + ', ' + item.first_name + '</span> ';

  if (item.company_name) {
		if (item.title) {
			html += '<div class="select2-result sub employee-title">' + item.title + '</div>';
		}
		
    html += '<div class="select2-result sub company-name">' + item.company_name + '</div>';
		html += '<div class="select2-result sub full-address">' + item.company_full_address + '</div>';
  }

  html += '</div></div>';

  return html;
} // formatFullUserItem()


function formatFullUserItemSelection(item) {
	// this function...
	// 	- formats how the select2 selected option looks and functions
	// 	- adds data attributes to the select2 "option" elements
	// 	- shows User with Employee and Company data (FULL)
	
	// set data attributes on select2's "option" elements
	// https://stackoverflow.com/a/48002164
	$(item.element).attr('data-user-id', item.id);
	$(item.element).attr('data-first-name', item.first_name);
	$(item.element).attr('data-last-name', item.last_name);
	$(item.element).attr('data-company-id', item.company_id);
	
	var nameOrText = item.text; // default
	
	if (item.first_name && item.last_name) {
		nameOrText = item.last_name + ", " + item.first_name;
	}
	
	var html = '<div class="select2-selection primary user-full-name">' + nameOrText + '</div>';
	
	if (item.company_name) {
		html += '<div class="select2-selection sub employee-title">' + item.title + '</div>';
		html += '<div class="select2-selection sub company-name">' + item.company_name + '</div>';
		html += '<div class="select2-selection sub full-address">' + item.company_full_address + '</div>';
	}
	
  return html;
} // formatFullUserItemSelection()
// --FULL


/**************************************************
*	utility methods
*/

function isMobile() {
	// idea: https://dev.to/oskarcodes/comment/1f5ch
	// 	- workaround for detecting iPad (navigator.maxTouchPoints)
	// 		- source: https://developer.apple.com/forums/thread/119186?answerId=623854022#623854022
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints != 0);
} // isMobile()


function clearTimer(timer) {
	// clears the setTimeout() object passed in
  	clearTimeout(timer);
} // clearTimer()


function toClassname(str) {
	// returns a string separated by single spaces into a dash separated string
	// 	e.g. "this is a string" -> "this-is-a-string"
	return str.split(" ").join("-");
} // toClassname()


function validateEmail(email) {
	// https://stackoverflow.com/a/46181
  var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	
  return regex.test(jQuery.trim(email));
} // validateEmail()


function isInteger(value) {
	// returns boolean
	
	// return false if value is blank
	if ($.trim(value) == "") { return false; }
	
	// 	- is value a whole number (e.g. integer)?
	return Number.isInteger(Number(value));
} // isInteger()


function getHighestNumber(array) {
	// array arg is an array of numbers
	var highestNumber = array.reduce(function(a, b) {
		return Math.max(a, b);
	});
	
	return String(highestNumber);
} // getHighestNumber()


function getUrlParameter(name) {
	// credit: https://davidwalsh.name/query-string-javascript
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
	
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
} // getUrlParameter()


function objectsAreEqual(a, b) {
	// compares two objects to determine if they are equal (duplicates)
    // create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // if number of properties is different, objects are not equal
    if (aProps.length != bProps.length) { return false; }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // if values of same property are not equal, objects are not equal
        if (a[propName] !== b[propName]) { return false; }
    }
		
    return true;
} // objectsAreEqual()


function objectInArray(array, object) {
	// takes an array of objects and compares object to each item in array via objectsAreEqual
	for (i = 0; i < array.length; i++) {
		if (objectsAreEqual(array[i], object)) {
			return true;
		}
	}
	
	return false;
} // objectInArray()


function uniqueArray(array) {
	// takes a simple array and returns a simple array with duplicates removed
  var results = [];
	
  $.each(array, function(i, element) {
    if ($.inArray(element, results) == -1) { results.push(element); }
  });
	
  return results;
} // uniqueArray()


function removeItemFromArray(value, array) {
	return array.filter(function (item) {
	    return item !== value
	});
} // removeItemFromArray()


function iOS() {
// https://stackoverflow.com/a/9039885
  var iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) { return true; }
    }
  }

  return false;
} // iOS()


function getScrollTop() {
	// returns vertical scroll position of page
	return $(window).scrollTop();
} // getScrollTop()


function goToScrollPosition(position) {
	// scroll page vertically to provided position
	$(window).scrollTop(position);
} // goToScrollPosition()


function preventBodyScrolling() {
	// prevents body from scrolling
	var htmlTag = $("html");
	var bodyTag = $("body");
	
	htmlTag.add(bodyTag).addClass("no-scroll");
} // preventBodyScrolling()


function restoreBodyScrolling() {
	// restores body scrolling
	var htmlTag = $("html");
	var bodyTag = $("body");
	
	htmlTag.add(bodyTag).removeClass("no-scroll");
} // restoreBodyScrolling()


function decodeHTML(html) {
	// https://gomakethings.com/decoding-html-entities-with-vanilla-javascript/
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
} // decodeHTML()


function buildArrayFromCheckboxes(checkboxes) {
	return checkboxes.map(function () {
		var checkbox = $(this);
		
		if (checkbox.is(":checked")) {
			return checkbox.val();
		}
		
		return null;
	}).get();
} // buildArrayFromCheckboxes()


function fileIsAnImage(file) {
	return /^image\//.test(file.type);
} // fileIsAnImage()


function fileIsAVideo(file) {
	return /^video\//.test(file.type);
} // fileIsAVideo()


/**************************************************
*	functions : file downloads
*/

// global timeout variable for all single file request downloads
var downloadTimeout;


var checkDownloadCookie = function (cookieName) {
	// this function listens for when the server changes the cookie value from 0 to 1
  if (getCookie(cookieName) == 1) {
    setCookie(cookieName, 0); // expiration can be anything, as long as we reset the value
    removeTopOverlay();
	
		return;
  }

	downloadTimeout = setTimeout(function () {
		checkDownloadCookie(cookieName);
	}, 1000); // re-run this function in 1 second
};


function initializeHttpFileDownloadIndicator(message) {
	// handles all single file, normal http request file downloads
	// 	- shows overlay with progress indicator
	//	- sends cookie to server. when server responds with request/file, the server updates the cookie value to 1 and the overlay message is removed (file download complete)
	
	// if cookies are not enabled, return early (precaution)
	if (!navigator.cookieEnabled) { return; }
	
	var cookieName = "download_status";
	
  showTopOverlay(message);
  setCookie(cookieName, 0); // expiration can be anything, as long as we reset the value
	
  setTimeout(function () {
  	checkDownloadCookie(cookieName);
  }, 1000); // initiate the loop to check the cookie for updates
} // initializeHttpFileDownloadIndicator()


function downloadFile(urlToSend) {
	// AJAX file downloads
	// idea from: https://stackoverflow.com/a/49674385
	var actionMessage = "Preparing download...";
	showTopOverlay(actionMessage);
	
	var req = new XMLHttpRequest();
	
	req.open("GET", urlToSend, true);
	req.responseType = "blob";
	//req.setRequestHeader('my-custom-header', 'custom-value'); // adding some headers (if needed)

	req.onload = function (event) {
		var blob = req.response;
		var fileName = null;
		var contentType = req.getResponseHeader("content-type");

		// IE/EDGE seems not returning some response header
	  	if (req.getResponseHeader("content-disposition")) {
	    	var contentDisposition = req.getResponseHeader("content-disposition");
			
			// gets file name from content-disposition header
			//	- removes extra double quotes around file name (from header)
	    	fileName = contentDisposition.substring(contentDisposition.indexOf("=") + 1).replace(/"/g, "");;
	  	}
		else {
	    	fileName = "unnamed." + contentType.substring(contentType.indexOf("/") + 1);
	  	}
		
		//console.log(urlToSend);
		//console.log(blob);
		//console.log(fileName);
		//console.log(contentType);
		
	  	if (window.navigator.msSaveOrOpenBlob) {
	    	// Internet Explorer
	    	window.navigator.msSaveOrOpenBlob(new Blob([blob], { type: contentType }), fileName);
	  	}
		else {
	    	var link = document.getElementById("download-callback-link");
	    	link.href = window.URL.createObjectURL(blob);
	    	link.download = fileName;
	    	link.click();
			
			//console.log(link);
			//console.log(link.href);
			//console.log(link.download);
	  	}
	}; // req.onload()
	
	// a "completed" function per mozilla's docs
	// 	- https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Using_XMLHttpRequest
	req.addEventListener("loadend", function (e) {
		// remove top overlay
		removeTopOverlay();
	});
	
	req.send();
} // downloadFile()


/**************************************************
*	functions : error handling
*/

function logClientError(formSubmissionId, typeOf, errorObject) {
	// CURRENTLY, ONLY USED FOR FORM SUBMISSIONS
	// this method retries itself until error is sent to server
	// NOTE: not using doAjax() here as this method needs to retry continually until successful
	var url = "/client-errors/create/";
	var sendData = {
		form_submission_id: formSubmissionId,
		type_of: typeOf,
		client_error: JSON.stringify(errorObject)
	};
	
  $.ajax({
  	type: "POST",
		url: url,
		data: sendData,
		cache: false,
  	headers: {
    	  "Cache-Control": "no-cache"
  	},
		timeout: 5000,
		    error: function (jqXHR, textStatus, errorThrown) {
			// retry ajax request
			var ajaxObject = this;
			
			setTimeout(function () {
				$.ajax(ajaxObject);
			}, 5000); // 5 seconds
    }
  }); // $.ajax()
} // logClientErrors()


function generalErrorHandler(errors, jqXHR) {
	// shows errors from an array of errors
	// jqXHR is optional arg
	if (typeof jqXHR != "undefined") {
		if (jqXHR.status == "404") {
			errors.push("Record not found");
		}
	
		if (jqXHR.status == "500") {
			errors.push("Internal Server Error");
		}
	}
	
	alert(errors.join(', '));
} // generalErrorHandler()


function getStatusTextMessage(statusCode) {
	var message;
	
	switch(statusCode) {
	    case 0:
        	message = "No Network Connection";
			
        	break;
	    case 404:
        	message = "404 Not Found";
				
        	break;
	    case 500:
        	message = "Internal Server Error";
				
        	break;
	}
	
	return message;
} // getStatusTextMessage()


/**************************************************
*	functions : ajax
*/

function doAjax(options, wrap) {
	// retries ajax call until done or fail
	// idea from: https://stackoverflow.com/a/39031614
	// 	- wrap is an html element used for testing. can pass in any html element
	
	// add custom properties (defaults) to options
	options.actionMessage = options.actionMessage || "Processing...";
	options.retryCount = 0; // don't allow this to get passed in
	
	// if the following options are not passed in, set their defaults
	if (typeof options.timeout === "undefined") {
		// 5000 (5 seconds) TESTING
		// 60000 (1 minute) default
		options.timeout = 60000;
	}
	if (typeof options.retry === "undefined") {
		options.retry = true;
	}
	if (typeof options.retryLimit === "undefined") {
		options.retryLimit = 3;
	}
	
	return new $.Deferred(function (dfd) {
    function request(opts) {
			$.ajax(opts)
			.done(function (data, textStatus, jqXHR) {
				if (typeof wrap !== "undefined") {
					// TESTING
					// 	- so we can show testing messages within wrapper html for the item performing the ajax request
					wrap.append("understanding done!");
				}
				
				dfd.resolve(data, textStatus, jqXHR);
			})
			.fail(function (jqXHR, textStatus, errorThrown) {
				if (typeof wrap !== "undefined") {
					// TESTING
					// 	- so we can show testing messages within wrapper html for the item performing the ajax request
					wrap.append('<span class="block bold">retry count: ' + this.retryCount + '</span>');
					wrap.append('<span class="block bold">textStatus: ' + textStatus + '</span>');
				}
				
				if (this.retry) {
	 			 	this.retryCount++;
					
					if (this.retryCount < this.retryLimit) {
						//console.log("retrying...");
						return request(this);
					}
				}
				
				// if suppressDefaultErrorMessages option is not passed in, process default error messages
				if (typeof this.suppressDefaultErrorMessages === "undefined") {
					var errorMessage = "Error " + this.actionMessage;
                  
					// handle ajax error messages
					handleAjaxErrorMessages(jqXHR, textStatus, errorMessage);
				}
			
  			dfd.rejectWith(this, [jqXHR]);
			});
    }
		
  	request(options);
  });
} // doAjax()


function handleAjaxErrorMessages(jqXHR, textStatus, errorMessage) {
	if (typeof jqXHR.responseJSON !== "undefined") {
		if (typeof jqXHR.responseJSON.errors !== "undefined") {
			var errors = jqXHR.responseJSON.errors;
			
			generalErrorHandler(errors, jqXHR);
			
			return;
		}
	}
	
	// manually set errors array
	var errors = [];
	
	if (textStatus == 'timeout') {
		errors.push("Request timed out. Please try again.");
	}
	
	// all other errors
	// - no network connection, unknown error...
	var generalErrorMessage = errorMessage + ": (status code: " + jqXHR.status + "): " + getStatusTextMessage(jqXHR.status);

	errors.push(generalErrorMessage);
	
	// alert user
	generalErrorHandler(errors);
} // handleAjaxErrorMessages()


/**************************************************
*	prototype methods
*/

String.prototype.blank = function () {
	return (jQuery.trim(this) == "") ? true : false;
}; // String.prototype.blank()


String.prototype.truncate = function (num, omission) {
		if (this.length <= num) {
			return this;
		}
	
	// set ommision text from argument or default
	var om = omission || '...';
	
	return this.slice(0, num) + om;
}; // String.prototype.truncate()


Array.prototype.inArray = function (item) {
	// returns true if item is in array, false if not
	return (this.indexOf(item) != -1) ? true : false;
}; // // Array.prototype.inArray()


Array.prototype.hasDuplicates = function () {
	// checks simple arrays for duplicate values
	// returns boolean
	var items = [];
		
	for (var i = 0; i < this.length; ++i) {
		var value = this[i];
		
  		if (items.indexOf(value) !== -1) {
			//console.log("this[i]: |" + this[i] + "|");
			//console.log("items: " + items);
  			return true;
  		}
		
  		items.push(value);
 		}
	
  	return false;
}; // Array.prototype.hasDuplicates()


Array.prototype.difference = function (array) {
	// compares two arrays and returns an array containing the non duplicate item(s) between both arrays
	// this method is for simple, non-nested arrays
	var newArray = [];
	
	// get longest array
	// call this the "comparing array", e.g. the values of this array will get compared to the other (smaller) array
	var comparingArray = (this.length > array.length) ? this : array;
	
	// determine the compared to array, e.g. the array the comparingArray gets compared to
	// find our if the comparingArray (longer array) ended up being this, if not set to array that was passed to this method
	var comparedToArray = (comparingArray.length == this.length) ? array : this;
	
	// get each item difference between arrays
	for (var i = 0; i < comparingArray.length; i++) {
		if (!comparedToArray.inArray(comparingArray[i])) {
			newArray.push(comparingArray[i]);
		}
	}
	
	return newArray;
}; // Array.prototype.difference()


Array.prototype.removeFromArray = function (item) {
	// idea: https://stackoverflow.com/a/5767357
	var index = this.indexOf(item);
	
	if (index > -1) {
	  this.splice(index, 1);
	}
	
	return this;
}; // Array.removeFromArray()


/**************************************************
*	polyfills
*/

// so our isInteger() function will work in IE
Number.isInteger = Number.isInteger || function (value) {
  return typeof value === 'number' &&
    isFinite(value) &&
    Math.floor(value) === value;
};


/**************************************************
*		jquery extend methods
*/

// credit: https://stackoverflow.com/a/2132230
$.extend($.fn.disableTextSelect = function () {
   return this.each(function () {
      if ($.browser.mozilla) { // firefox
		  $(this).css('MozUserSelect', 'none');
      }
	  else if ($.browser.msie) { // ie
		  $(this).bind('selectstart', function () { return false; });
      } 
	  else { // opera, etc.
		  $(this).mousedown(function () { return false; });
      }
   });
});


/**************************************************
*	dom ready
*	- method calls
*	- events
* - !!! IMPORTANT !!!
* 	- why we are listening for turbolinks.load instead of standard jQuery $(function () {})
* 		- https://stackoverflow.com/a/60309785/17038759
*/

$(document).on("turbolinks:load", () => {
	// initialize tippy tooltips
	// info on body -> target strategy...
	// 	- https://stackoverflow.com/a/52382125
	//tippy('body', {
	//	target: '[data-tippy-content]',
	//	boundary: 'window' // tooltips were getting cut off in Form Builder
	//});
	
	// initialize time pickers
	initializeTimePicker($(".flatpickr"));
	
	var timePickerContainers = $(".time-picker-container");
	
	// initialize time picker controls
	timePickerContainers.each(function () {
		initializeTimePickerControls($(this));
	});
	
	// set anchor links
	var anchors = $("a");
  
  setLinks(anchors);
	
	showAlerts();
	
	togglePrimaryNavigation();
	
	toggleDropNavigations($(".has-dropdown"));
	
	// prevent double clicks on unimpersonate links
	var unimpersonateLinks = $(".unimpersonate");

	unimpersonateLinks.each(function () {
		e = $(this);
	
		e.click(function (event) {
			if (!e.hasClass("no-double-click")) {
				e.addClass("no-double-click");
			
				return;
			}
		
			event.stopPropagation();
			event.preventDefault();
		});
	});

	// mobile nav
	var navMobileToggle = $(".toggle.nav.mobile");
	var navPrimary = $("nav.primary");
	var navPrimaryList = navPrimary.find("ul.nav.primary"); // get nav list (ul.nav.primary)
	var navPrimaryClose = navPrimary.find(".header ul.controls li.control.close");
	
	// when on mobile device, remove "no-mobile" class on primary nav to prevent hover effects on mobile devices
	if (iOS()) {
		navPrimary.removeClass("no-mobile");
	}

	// toggle mobile nav when mobile nav button is clicked
  navMobileToggle.click(function (event) {
		//event.stopPropagation();
		$("body").toggleClass("primary-nav-open");
		
		// if wider than 1025px, if .toggle.nav.mobile is clicked, always remove "open" classname on primary nav since it's not ussed unless on mobile widths and could cause unexpected UI behavior
		if ($(window).width() < 1025) {
			navPrimary.removeClass("open");
		}
		
		// only apply body scroll lock if window width is below mobile break point
		if ($(window).width() < 1025) {
			navPrimary.toggleClass("open");
			
			// toggle body scroll lock
			if (navPrimary.hasClass("open")) {
				// prevent body scrolling
				// https://medium.com/jsdownunder/locking-body-scroll-for-all-devices-22def9615177
				// https://github.com/willmcpo/body-scroll-lock
				enableBodyScrollLock(navPrimaryList[0]);
			}
			else {
				// restore body scrolling
				disableBodyScrollLock(navPrimaryList[0]);
			}
		}
  });
	
	// close button for primary nav
	navPrimaryClose.click(function (event) {
		$("body").removeClass("primary-nav-open");
		navPrimary.removeClass("open");
		
		// restore body scrolling
		disableBodyScrollLock(navPrimaryList[0]);
	});
	
	// start jquery timeago on any date/time elements
	$("time.timeago").timeago();

	// close mobile nav if anywhere on document other than nav element is clicked
	//$(document).click(function (event) {
	//    if (navPrimary.is(":visible") && !navMobileToggle.is(event.target)) {
	//    	navPrimary.removeClass("open");
	//    }
	//});
}); // dom ready
