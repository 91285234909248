//////////////////////////////////////////////////////////
//
//  Script: Dates and Times
//  copyright (c): Craig Nelson / Field Tools
//
//  features:
//	- methods to handle dates and times
//

	function getCurrentDate() {
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth() + 1; // January is 0!
		var yyyy = today.getFullYear();

		if (dd < 10) {
			dd = '0' + dd;
		} 

		if (mm < 10) {
			mm = '0' + mm;
		} 

		return yyyy + '-' + mm + '-' + dd;
	} // getCurrentDate()
	
	
	function formatDateTimeString(date, time) {
		// returns datetime string formatted for database storage
		// if only time is passed in or date and time are both blank strings, datetime will return null
	
		// trim any whitespace from date and time
		// this is so the if conditions below will evaluate as expected. javascript evaluates "" as false
		date = $.trim(date);
		time = $.trim(time);
	
		var datetime = null;
	
		if (date && time) { datetime = date + " " + time; }
		if (date && !time) { datetime = date; }
	
		return datetime;
	} // formatDateTimeString()
	
	
	function formatDateTime(date, format) {
		// returns a formated date and or time string
		// NOTE: date coming in can be a string, a JS date object or a moment.js date object
		return moment(date).format(format);
	} // formatDateTime()
	
	
	function updateTime() {
		var timeDisplays = $(".time");
	  	var time = moment().format('dddd, MMM Do YYYY h:mm A');
		
		timeDisplays.each(function () {
			var timeDisplay = $(this);
			
			timeDisplay.html(time);
		});
	} // updateTime()
	
	
	function timeFormat(timeStr) {
		// converts military time format to AMPM
	    var parts = timeStr.split(':');
	    var hour = parseInt(parts[0]);
	    var suffix = hour >= 12 ? ' PM' : ' AM';
	    
		hour = (hour + 11) % 12 + 1;
		
	    return (String(hour)).substr(-2) + ':' + parts[1] + suffix;
	} // timeFormat()
	
	
	function viewingPastDate(viewingDateStr) {
		// compares today and incoming date by "date", exapmple: "2020/02/01", not by timestamp (datetime)
		// 	- dates objects use getDate() to get day of month as number to compare
		// get TODAY's date (returns timestamp)
		var todayDateStr = getCurrentDate();
		
		if (moment(viewingDateStr).isBefore(todayDateStr)) { return true; }
		
		return false;
	} // viewingPastDate()
	
	
	function viewingToday(dateStr) {
		// compares today and incoming date by "date", exapmple: "2020/02/01", not by timestamp (datetime)
		// 	- dates objects use getDate() to get day of month as number to compare
		// get TODAY's date (returns timestamp)
		var todayDateObj = new Date(getCurrentDate());
		
		// get date (day) of month (returns integer)
		var today = todayDateObj.getDate();
		
		// date to compare to TODAY
		var dateObj = new Date(dateStr);
		var date = dateObj.getDate();
		
		if (date == today) { return true; }
		
		return false;
	} // viewingToday()
	
	
	function viewingFutureDate(dateStr) {
		// compares today and incoming date by "date", exapmple: "2020/02/01", not by timestamp (datetime)
		// 	- dates objects use getDate() to get day of month as number to compare
		// get TODAY's date (returns timestamp)
		var todayDateObj = new Date(getCurrentDate());
		
		// get date (day) of month (returns integer)
		var today = todayDateObj.getDate();
		
		// date to compare to TODAY
		var dateObj = new Date(dateStr);
		var date = dateObj.getDate();
		
		if (date > today) { return true; }
		
		return false;
	} // viewingFutureDate()