// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// info on working with JS in Rails 6 with webpacker
// 	- https://blog.capsens.eu/how-to-write-javascript-in-rails-6-webpacker-yarn-and-sprockets-cdf990387463
// 	- https://www.tutorialspoint.com/es6/es6_modules.htm

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//import "stylesheets/application"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("@fortawesome/fontawesome-free");

// tippy js
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
window.tippy = tippy;

//require("../vendor/bodyScrollLock/bodyScrollLock");
//require("../vendor/chartist-0.11.0/chartist.min");
//require("../vendor/clipboard.js/clipboard.min");
//require("../vendor/contentEditable/contentEditable");
require("../vendor/flatpickr/flatpickr.min");
//require("../vendor/jquery-numeric/jquery.numeric.min");
//require("../vendor/jquery-touch-punch/jquery.ui.touch-punch.min");
//require("../vendor/jquery.finger/jquery.finger");
//require("../vendor/jquery.formatDateTime/jquery.formatDateTime.min");
//require("../vendor/jquery.scrollTo-2.1.2/jquery.scrollTo.min");
//require("../vendor/math.js/math.min");
//require("../vendor/moment.js/moment");
//require("../vendor/numeral.js/numeral.min");
require("../vendor/pickadate.js-3.6.4/picker");
require("../vendor/pickadate.js-3.6.4/picker.date");
require("../vendor/pickadate.js-3.6.4/picker.time");
//require("../vendor/select2-4.0.13/select2.full.min");
require("../vendor/timeago/jquery.timeago");
require('jquery-ui');

// custom js
require("packs/global")
require("packs/utilities/dates_times")
